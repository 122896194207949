import React from 'react';
import ComponentContainer from './ComponentContainer';
import { IComponentElement } from '../lib/types';

export default function FormElements() {
    return (
        <div>
            <ComponentContainer component={SearchForm} />
            <ComponentContainer component={SubscribeForm} />
        </div>
    );
}

// search input
const SearchForm = ({ ComponentButtons, codeRef }: IComponentElement) => {
    return (
        <div className="flex justify-between">
            <form ref={codeRef as React.RefObject<HTMLFormElement>} className="flex gap-2 bg-ppc-gray5 items-center pl-3 w-full color-ppc-gray2">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#4f4f4f" className="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                </svg>
                <input type="text" placeholder="Search..." className="px-4 py-2 bg-transparent grow outline-none" />
            </form>
            {ComponentButtons}
        </div>
    );
}

const SubscribeForm = ({ ComponentButtons, codeRef }: IComponentElement) => {
    return (
        <div className="flex justify-between">
            <div className='flex gap-2 p-2 bg-ppc-peachBlush'>
                <form ref={codeRef as React.RefObject<HTMLFormElement>} className="flex gap-2 bg-white items-center pr-3 w-full color-ppc-gray2">
                    <input type="text" placeholder="Enter Email" className="px-4 py-2 bg-transparent grow outline-none" />
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.3497 17.5L11.9543 15.2148L10.5851 16.3628L10.029 13.9189L15.9587 8.72567L8.84882 12.8097L5.5 10.3935L18.5 6.5L15.3497 17.5Z" fill="#333333" />
                    </svg>
                </form>
            </div>
            {ComponentButtons}
        </div>
    );
}