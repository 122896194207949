import React from 'react';
import ComponentContainer from './ComponentContainer';
import { IComponentElement } from '../lib/types';

export default function ProductItem() {
    return (
        <div>
            <ComponentContainer component={ProductItemSection} />
        </div>
    );
}


const ProductItemSection = ({ ComponentButtons, codeRef }: IComponentElement) => {
    return (
        <div>
            <div className="flex justify-between">
                <div className="max-w-xs">
                    <div className="aspect-product relative w-full group cursor-pointer">
                        <img src="/assets/product-image.jpeg" alt="Product" ref={codeRef as React.RefObject<HTMLImageElement>} className="object-cover aspect-product h-full w-full" />
                        <div className="absolute w-full bottom-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                            <button className="uppercase w-full p-2 bg-ppc-wb20 bg-opacity-90 font-bold">Quick View</button>
                        </div>
                        <div className="absolute top-2 right-2 p-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-white bg-opacity-60 rounded-full">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 25 24" fill="none" className="hover:fill-ppc-bark60">
                                <path d="M23.9999 8.71292C23.9999 10.5082 23.317 12.2325 22.0975 13.508C19.2904 16.4451 16.5676 19.5078 13.6556 22.3385C12.9881 22.9779 11.9293 22.9545 11.2905 22.2862L2.90092 13.508C0.365059 10.8547 0.365059 6.57115 2.90092 3.91782C5.4617 1.23842 9.63348 1.23842 12.1942 3.91782L12.4992 4.23688L12.804 3.91801C14.0318 2.63267 15.7039 1.90771 17.4507 1.90771C19.1976 1.90771 20.8696 2.6326 22.0975 3.91782C23.3171 5.19346 23.9999 6.91771 23.9999 8.71292Z" stroke="#814B27" stroke-width="2" stroke-linejoin="round"></path>
                            </svg>
                        </div>
                        <div className="absolute left-2 bg-white bg-opacity-60 p-2 -rotate-90 rotate-270 top-0 origin-top-left inline-block translate-x-[-100%]">
                            <span className="text-sm">Limited Edition</span>
                        </div>
                    </div>
                </div>
                {ComponentButtons}
            </div>
        </div>
    );
};