import React from 'react'
import ComponentContainer from './ComponentContainer'
import { IComponentElement } from '../lib/types'

export default function Headings() {
  return (
    <div>
        <ComponentContainer component={Heading1} />
        <ComponentContainer component={ProfileText} />
        <ComponentContainer component={DeleteUserText} />
        <ComponentContainer component={UserEmailText} />
        <ComponentContainer component={EmailText} />
        <ComponentContainer component={UserSettingsText} />
        <ComponentContainer component={DateText} />
        <ComponentContainer component={ForgotPasswordText} />
        <ComponentContainer component={MetaText} />
        <ComponentContainer component={ClientName1Text} />
        <ComponentContainer component={ForgotAboutBudgetText} />
        <ComponentContainer component={GetStartedText} />
        <ComponentContainer component={RemovingYourProfileText} />
        <ComponentContainer component={RememberCredentialsText} />
        <ComponentContainer component={StatusText} />
        <ComponentContainer component={DateRangeText} />
        <ComponentContainer component={DateValidText} />
    </div>
  )
}

const Heading1 = ({ ComponentButtons, codeRef }: IComponentElement) => {
    return (
        <div className="flex justify-between">
            <h1 ref={codeRef as React.RefObject<HTMLDivElement>} className="text-[53px] leading-[64px] font-bold font-montserrat">Heading 1</h1>
            {ComponentButtons}
        </div>
    )
}

const ProfileText = ({ ComponentButtons, codeRef }: IComponentElement) => {
    return (
        <div className="flex justify-between">
            <p ref={codeRef as React.RefObject<HTMLDivElement>} className="text-[31px] leading-[37px] font-semibold font-montserrat">Profile</p>
            {ComponentButtons}
        </div>
    )
}

const DeleteUserText = ({ ComponentButtons, codeRef }: IComponentElement) => {
    return (
        <div className="flex justify-between">
            <p ref={codeRef as React.RefObject<HTMLDivElement>} className="text-[31px] leading-[37px] font-semibold font-montserrat">Delete User Account</p>
            {ComponentButtons}
        </div>
    )
}

const UserEmailText = ({ ComponentButtons, codeRef }: IComponentElement) => {
    return (
        <div className="flex justify-between">
            <p ref={codeRef as React.RefObject<HTMLDivElement>} className="text-[29px] leading-[35px] font-medium text-ppc-gray5 font-montserrat">johnsmith@examplemail.com</p>
            {ComponentButtons}
        </div>
    )
}

const EmailText = ({ ComponentButtons, codeRef }: IComponentElement) => {
    return (
        <div className="flex justify-between">
            <p ref={codeRef as React.RefObject<HTMLDivElement>} className="text-[26px] leading-[31px] font-semibold font-montserrat">Email</p>
            {ComponentButtons}
        </div>
    )
}

const UserSettingsText = ({ ComponentButtons, codeRef }: IComponentElement) => {
    return (
        <div className="flex justify-between">
            <p ref={codeRef as React.RefObject<HTMLDivElement>} className="text-[22px] leading-[26px] font-semibold font-montserrat">User Settings</p>
            {ComponentButtons}
        </div>
    )
}

const DateText = ({ ComponentButtons, codeRef }: IComponentElement) => {
    return (
        <div className="flex justify-between">
            <p ref={codeRef as React.RefObject<HTMLDivElement>} className="text-[20px] leading-[24px] font-medium font-montserrat uppercase">JANuARY 2024</p>
            {ComponentButtons}
        </div>
    )
}

const ForgotPasswordText = ({ ComponentButtons, codeRef }: IComponentElement) => {
    return (
        <div className="flex justify-between">
            <p ref={codeRef as React.RefObject<HTMLDivElement>} className="text-[19px] leading-[23px] font-semibold font-montserrat">Forgot password?</p>
            {ComponentButtons}
        </div>
    )
}

const MetaText = ({ ComponentButtons, codeRef }: IComponentElement) => {
    return (
        <div className="flex justify-between">
            <p ref={codeRef as React.RefObject<HTMLDivElement>} className="text-[18px] leading-[22px] font-medium font-montserrat">Meta</p>
            {ComponentButtons}
        </div>
    )
}

const ClientName1Text = ({ ComponentButtons, codeRef }: IComponentElement) => {
    return (
        <div className="flex justify-between">
            <p ref={codeRef as React.RefObject<HTMLDivElement>} className="text-[17px] leading-[21px] font-medium font-montserrat">Client Name 1</p>
            {ComponentButtons}
        </div>
    )
}

const ForgotAboutBudgetText = ({ ComponentButtons, codeRef }: IComponentElement) => {
    return (
        <div className="flex justify-between">
            <p ref={codeRef as React.RefObject<HTMLDivElement>} className="text-[15px] leading-[18px] font-medium text-ppc-gray3 font-montserrat">Forget about budget pacing concerns forever</p>
            {ComponentButtons}
        </div>
    )
}

const GetStartedText = ({ ComponentButtons, codeRef }: IComponentElement) => {
    return (
        <div className="flex justify-between">
            <p ref={codeRef as React.RefObject<HTMLDivElement>} className="text-[15px] leading-[18px] font-semibold text-ppc-gray3 font-montserrat">Lets get started!</p>
            {ComponentButtons}
        </div>
    )
}

const RemovingYourProfileText = ({ ComponentButtons, codeRef }: IComponentElement) => {
    return (
        <div className="flex justify-between">
            <p ref={codeRef as React.RefObject<HTMLDivElement>} className="text-[15px] leading-[29px] font-normal text-ppc-black font-montserrat">Removing your profile will result in the loss of access</p>
            {ComponentButtons}
        </div>
    )
}

const RememberCredentialsText = ({ ComponentButtons, codeRef }: IComponentElement) => {
    return (
        <div className="flex justify-between">
            <p ref={codeRef as React.RefObject<HTMLDivElement>} className="text-[14px] leading-[17px] font-semibold text-ppc-gray3 font-montserrat">Remember credentials</p>
            {ComponentButtons}
        </div>
    )
}

const StatusText = ({ ComponentButtons, codeRef }: IComponentElement) => {
    return (
        <div className="flex justify-between">
            <p ref={codeRef as React.RefObject<HTMLDivElement>} className="text-[11px] leading-[13px] font-semibold text-ppc-black font-montserrat uppercase">STATUS</p>
            {ComponentButtons}
        </div>
    )
}

const DateRangeText = ({ ComponentButtons, codeRef }: IComponentElement) => {
    return (
        <div className="flex justify-between">
            <p ref={codeRef as React.RefObject<HTMLDivElement>} className="text-[11px] leading-[13px] font-medium font-montserrat uppercase">DATE RANGE</p>
            {ComponentButtons}
        </div>
    )
}

const DateValidText = ({ ComponentButtons, codeRef }: IComponentElement) => {
    return (
        <div className="flex justify-between">
            <p ref={codeRef as React.RefObject<HTMLDivElement>} className="text-[10px] leading-[12px] font-medium font-montserrat uppercase">DATE VALID THROUGH</p>
            {ComponentButtons}
        </div>
    )
}
