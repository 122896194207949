import { Toaster } from "./components/ui/sonner";
import Buttons from "./ui/Buttons";
import Colors from "./ui/Colors";
import FormElements from "./ui/FormElements";
import Headings from "./ui/Headings";
import HeroSection from "./ui/HeroSection";
import Icons from "./ui/Icons";
import Images from "./ui/Images";
import ProductItem from "./ui/ProductItem";
import './App.scss';

export default function App() {
  return (
    <div className="grid min-h-screen w-full md:grid-cols-[220px_1fr] lg:grid-cols-[280px_1fr]">
      <div className="border-r bg-muted/40 md:block">
        <div className="flex h-full flex-col gap-2">
          <div className="flex h-14 items-center border-b px-4 lg:h-[60px] lg:px-6">
            <a href="/" className="flex items-center gap-2 font-semibold">
              {/* TODO: add project logo here <img src="/assets/logo.png" alt="Northern Reflect" className="h-6" /> */}
              PPC PACING TOOL
            </a>
          </div>
          <div className="flex-1">
            <nav className="grid items-start px-2 text-sm font-medium lg:px-4 sticky top-0 bottom-0">
              <h1 className="px-3">UI Development Kit: Elements</h1>
              <a href="#typography"
                className="flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-white hover:bg-slate-700"
              >
                Typography
              </a>
              <a href="#colors"
                className="flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-white hover:bg-slate-700"
              >
                Colors
              </a>
              <a href="#buttons"
                className="flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-white hover:bg-slate-700"
              >
                Buttons
              </a>
              <a href="#icons"
                className="flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-white hover:bg-slate-700"
              >
                Icons
              </a>
              <a href="#images"
                className="flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-white hover:bg-slate-700"
              >
                Images
              </a>
              <a href="#forms"
                className="flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-white hover:bg-slate-700"
              >
                Form Elements
              </a>
              <h1 className="px-3 pt-10">UI Development Kit: Components</h1>
              <a href="#hero"
                className="flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-white hover:bg-slate-700"
              >
                Hero Section
              </a>
            </nav>
          </div>
        </div>
      </div>
      <main className="p-6 max-w-ppc w-full mx-auto">
          <h1 className="text-3xl font-semibold mb-3 pb-3 mt-8 border-b border-slate-500" id="typography">Typography</h1>
          <Headings />
          <h1 className="text-3xl font-semibold mb-3 py-3 mt-8 border-b border-slate-500" id="typography">Colors</h1>
          <Colors />
          <h1 className="text-3xl font-semibold mb-3 py-3 mt-8 border-b border-slate-500" id="buttons">Buttons</h1>
          <Buttons />
          <h1 className="text-3xl font-semibold mb-3 py-3 mt-8 border-b border-slate-500" id="icons">Icons</h1>
          <Icons />
          <h1 className="text-3xl font-semibold mb-3 py-3 mt-8 border-b border-slate-500" id="images">Images</h1>
          <Images />
          <h1 className="text-3xl font-semibold mb-3 py-3 mt-8 border-b border-slate-500" id="forms">Form Elements</h1>
          <FormElements />
          <h1 className="text-3xl font-semibold mb-3 py-3 mt-8 border-b border-slate-500" id="hero">Hero Section</h1>
          <HeroSection />
          <h1 className="text-3xl font-semibold mb-3 py-3 mt-8 border-b border-slate-500" id="product">Product Item</h1>
          <ProductItem />
      </main>
      <Toaster />
    </div>
  )
}