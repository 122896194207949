import React from 'react';
import ComponentContainer from './ComponentContainer';
import { IComponentElement } from '../lib/types';

export default function Colors() {
    return (
        <div>
            <ComponentContainer component={ColorsComponent} />
        </div>
    );
}

const ColorsComponent = ({ ComponentButtons, codeRef }: IComponentElement) => {
    return (
        <div>
            <div className="flex justify-between">
                <div ref={codeRef as React.RefObject<HTMLDivElement>} className="flex gap-2 flex-wrap">
                    <div className="w-10 h-10 bg-ppc-yellow"></div>
                    <div className="w-10 h-10 bg-ppc-boldblack"></div>
                    <div className="w-10 h-10 bg-ppc-flame"></div>
                    <div className="w-10 h-10 bg-gradient"></div>
                </div>
                {ComponentButtons}
            </div>
            <div className='mt-5'>
                <h5>Custom Colors in tailwind.config.js</h5>
                <p>In this component, we have created a custom set of colors for PPC Pacing Tool and integrated them into our Tailwind CSS configuration. The custom colors are prefixed with ppc- to distinguish them from Tailwind's default color palette.</p>
            </div>
        </div>

    );
};
