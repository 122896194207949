import React from 'react';
import ComponentContainer from './ComponentContainer';
import { IComponentElement } from '../lib/types';

export default function Buttons() {
    return (
        <div>
            <ComponentContainer component={PrimaryButton} />
            <ComponentContainer component={PrimaryButtonWithIcon} />
            <ComponentContainer component={FullWidth} />
        </div>
    );
}

// ppc-primary
const PrimaryButton = ({ ComponentButtons, codeRef }: IComponentElement) => {
    return (
        
        <div className="flex justify-between  pb-4">
            <div className="flex justify-between w-full pr-4">
                <p className="text-[27px] leading-[32px] font-semibold text-ppc-gray2 font-montserrat">Primary</p>
                <button ref={codeRef as React.RefObject<HTMLButtonElement>} className="button">Client Name</button>
            </div>
            {ComponentButtons}
        </div>
    );
}

const PrimaryButtonWithIcon = ({ ComponentButtons, codeRef }: IComponentElement) => {
    return (
        <div className="flex justify-between  pb-4">
            <div className="flex justify-between w-full pr-4">
                <p className="text-[27px] leading-[32px] font-semibold text-ppc-gray2 font-montserrat">Primary (With icon)</p>
                <button ref={codeRef as React.RefObject<HTMLButtonElement>} className="button-with-icon">
                    Add New Client
                    <i className="icon-plus ml-4"></i>
                </button>
            </div>
            {ComponentButtons}
        </div>
    );
}

const FullWidth = ({ ComponentButtons, codeRef }: IComponentElement) => {
    return (
        <div className="flex justify-between">
            <button ref={codeRef as React.RefObject<HTMLButtonElement>} className="button-full">Save Changes</button>
            {ComponentButtons}
        </div>
    );
}
